@import "https://fonts.googleapis.com/css2?family=Antonio:wght@500&family=Space+Grotesk:wght@500;700&display=swap";
:root {
  --c-black: #151515;
  --c-gray: #242424;
  --c-gray-light: #b3b3b3;
  --c-white: #e6e6e6;
  --c-green: #4ee1a0;
  --c-error: #ff6f5b;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--c-black);
  color: var(--c-white);
  margin: 0 auto;
  font-family: Space Grotesk, sans-serif;
}

main {
  max-width: 1134px;
  margin: 0 auto;
}

.form {
  --width-of-input: 445px;
  --border-height: 1px;
  --border-before-color: var(--c-white);
  --border-after-color: var(--c-green);
  --border-error-color: var(--c-error);
  --input-hovered-color: #4ee1a00d;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0;
  position: relative;
}

.form:first-of-type {
  margin-top: 0;
}

.input {
  color: var(--c-white);
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: var(--border-height) solid var(--border-before-color);
  background-color: #0000;
  padding-block: .7em;
  padding-inline: .5em;
  font-family: Space Grotesk, sans-serif;
  font-size: 16px;
}

.input-border {
  background: var(--border-after-color);
  width: 0%;
  height: 2px;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.textarea-border {
  bottom: 4px;
}

input:hover, textarea:hover {
  background: var(--input-hovered-color);
}

input:focus, textarea:focus {
  outline: none;
}

input:focus ~ .input-border, textarea:focus ~ .input-border {
  width: 100%;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px var(--c-gray) inset !important;
  color: var(--c-white) !important;
}

input:autofill {
  box-shadow: 0 0 0 30px var(--c-gray) inset !important;
  color: var(--c-white) !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--c-gray) inset !important;
  -webkit-text-fill-color: var(--c-white) !important;
}

.btn {
  border: none;
  border-bottom: 2px solid var(--c-green);
  color: var(--c-white);
  cursor: pointer;
  text-transform: uppercase;
  z-index: 0;
  background: none;
  padding: 10px 8px;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
  position: relative;
}

.btn:hover {
  color: var(--c-black);
  transition-delay: .5s;
}

.btn:before {
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--c-green);
  transition: all .5s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.btn:hover:before {
  width: 100%;
}

.btn:after {
  content: "";
  height: 0;
  width: 100%;
  background-color: var(--c-green);
  z-index: -1;
  transition: all .4s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.btn:hover:after {
  height: 100%;
  color: #f0f8ff;
  transition-delay: .4s;
}

.submit-btn {
  margin-top: 28px;
}

.header-1 {
  letter-spacing: -2.5px;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
}

@media (min-width: 40rem) {
  .header-1 {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (min-width: 55rem) {
  .header-1 {
    font-size: 88px;
    line-height: 88px;
  }
}

.header-2 {
  letter-spacing: -1.5px;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}

@media (min-width: 40rem) {
  .header-2 {
    font-size: 48px;
    line-height: 56px;
  }
}

.header-3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.body-1 {
  color: var(--c-gray-light);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 40rem) {
  .body-1 {
    font-size: 18px;
    line-height: 28px;
  }
}

.error-text {
  text-align: end;
  color: var(--c-error);
  margin-bottom: -6px;
  font-size: 12px;
}

.lazy-img {
  filter: blur(3px);
}

/*# sourceMappingURL=index.3fc480ca.css.map */
