@use './breakpoints' as *;
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@500&family=Space+Grotesk:wght@500;700&display=swap');
:root {
  --c-black: #151515;
  --c-gray: #242424;
  --c-gray-light: #b3b3b3;
  --c-white: #e6e6e6;
  --c-green: #4ee1a0;
  --c-error: #ff6f5b;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  background-color: var(--c-black);
  font-family: 'Space Grotesk', sans-serif;
  color: var(--c-white);
  margin: 0 auto;
}

main {
  max-width: 1134px;
  margin: 0 auto;
}

.form {
  --width-of-input: 445px;
  --border-height: 1px;
  --border-before-color: var(--c-white);
  --border-after-color: var(--c-green);
  --border-error-color: var(--c-error);
  --input-hovered-color: rgba(78, 225, 160, 0.05);
  position: relative;
  // width: var(--width-of-input);
  width: 100%;
  margin-bottom: 0px;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0px;
  }
}

/* styling of Input */
.input {
  color: var(--c-white);
  font-size: 16px;
  // text-transform: uppercase;
  font-family: 'Space Grotesk', sans-serif;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
  border-bottom: var(--border-height) solid var(--border-before-color);
}
/* styling of animated border */
.input-border {
  position: absolute;
  background: var(--border-after-color);
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}

.textarea-border {
  bottom: 4px;
}

/* Hover on Input */
input:hover,
textarea:hover {
  background: var(--input-hovered-color);
}

input:focus,
textarea:focus {
  outline: none;
}
/* here is code of animated border */
input:focus ~ .input-border,
textarea:focus ~ .input-border {
  width: 100%;
}

/* Change the white to any color */

input:autofill {
  box-shadow: 0 0 0 30px var(--c-gray) inset !important;
  color: var(--c-white) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--c-gray) inset !important;
  -webkit-text-fill-color: var(--c-white) !important;
}

.btn {
  text-decoration: none;
  border: none;
  border-bottom: 2px solid var(--c-green);
  color: var(--c-white);
  padding: 10px 8px;
  display: inline-block;
  transition: all 0.1s;
  cursor: pointer;
  text-transform: uppercase;
  background: transparent;
  position: relative;
  transition: 0.5s ease;
  z-index: 0;

  &:hover {
    color: var(--c-black);
    // color: #1e1e2b;
    transition-delay: 0.5s;
  }
}
.btn::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: var(--c-green);
  transition: 0.5s ease;
}

.btn:hover::before {
  width: 100%;
}

.btn::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background-color: var(--c-green);
  transition: 0.4s ease;
  z-index: -1;
}

.btn:hover::after {
  height: 100%;
  transition-delay: 0.4s;
  color: aliceblue;
}
.submit-btn {
  margin-top: 28px;
}

.header-1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  /* identical to box height, or 100% */

  letter-spacing: -2.5px;

  @include respond-to('small') {
    font-size: 72px;
    line-height: 72px;
  }
  @include respond-to('medium') {
    font-size: 88px;
    line-height: 88px;
  }
}

.header-2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 117% */

  letter-spacing: -1.5px;

  @include respond-to('small') {
    font-size: 48px;
    line-height: 56px;
  }
}

.header-3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.body-1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-gray-light);

  @include respond-to('small') {
    font-size: 18px;
    line-height: 28px;
  }
}

.error-text {
  text-align: end;
  color: var(--c-error);
  font-size: 12px;
  margin-bottom: -6px;
}

.lazy-img {
  filter: blur(3px);
}
